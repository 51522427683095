<template>
    <main>
    <div class="m-3">
      <div class="cont-search-a mt-3">
        <div class="container-input-search">
          <form @keypress="filter()" class="input-group">
            <input type="search" class="form-control" v-model="searchInput"
              placeholder="Buscar por título, ubicación o modalidad" />
          </form>
        </div>
      </div>
      <hr>
      <!-- Tarjeta Publicada Vacante -->
      <div class="new-format-view-vacantes">
        <div class="talento-content" id="vacante" style="overflow-y: auto;">
          <div style="max-height: 75vh;">
            <div class="talento-item" v-for="(item, i) in listVacantes" :key="i + 1" v-show="filter(item)">
              <div class="talento-text-content" @click="viewVacante(item)" style="cursor: pointer;">
                <div class="text-public-vacante">
                  <span>Publicada {{ moment(item.createdAt).tz(`${userTimeZone}`).from() }}
                  </span>
                </div>
                <div style="display: flex;">
                  <div style="width: 30%;">
                    <div class="information-image-box" v-if="!item.private">
                      <img class="rounded-circle"
                        :src="item.authorVacante.photo ? item.authorVacante.photo.link : ''"
                        alt="profile image" style="width: 100%; aspect-ratio: 1/1; object-fit: cover;">
                    </div>
                    <img v-else src="/images/profile.jpg" alt="Logo Company" class="rounded-circle"
                        style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                  </div>
                  <div style="width: 70%; margin-top: 3%;">
                    <span class="text-title">{{ item.titulo }}</span>
                    <div class="text-body mb-2" v-if="!item.privateSalary">
                      <span>{{ item.becaDe }} - {{ item.becaA }} Mensual</span>
                    </div>
                    <div class="text-body mb-2" v-if="item.privateSalary">
                      <span v-show="item.type ==='Becario'">Beca Competitiva</span>
                      <span v-show="item.type ==='Egresado'">Salario Competitivo</span>
                    </div>
                    <div class="text-body">
                      {{ item.ubicacion }}, {{ item.estado }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="J-talento-btn-content J-flex end">
                <span v-if="item.loading">
                  <div class="spinner-border J-spinner" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="new-view-information-vacantes" id="information">
          <div class="container-view-vacant" v-if="vacante.company">
            <section>
              <div class="previous-btn mb-2" id="previous" v-show="window <= 1024">
                <button type="button" class="btn btn-outline-primary"
                  style="display: flex; justify-content: center; align-items: center; font-size: 19px;"
                  @click="returnView()">
                  <box-icon name='arrow-back'></box-icon>
                </button>
              </div>
              <div class="section-header">
                <div style="width: 80%;">
                  <div class="vacante-header-title-a">
                    <h4>{{ vacante.titulo }}</h4>
                  </div>
                  <div class="ubicacion">
                    <h5 v-if="!vacante.private">{{ vacante.company.nameCompany }}</h5>
                    <h5 style="font-weight: bold;" v-else>EMPRESA CONFIDENCIAL</h5>
                    <span>{{ vacante.ubicacion }}, {{ vacante.estado }}</span>
                  </div>
                  <div>
                    <div class="salario" v-if="!vacante.privateSalary">
                      <span style="font-size: 17px;">{{ vacante.becaDe }} mensual</span>
                    </div>
                    <div class="salario" v-if="vacante.privateSalary">
                      <span style="font-size: 17px;" v-show="vacante.type ==='Becario'">Beca Competitiva</span>
                      <span style="font-size: 15px;" v-show="vacante.type ==='Egresado'">Salario Competitivo</span>
                    </div>
                  </div>
                </div>
                <div class="section-information-image" style="width: 20%;">
                  <div class="information-image-box">
                    <img v-if="!vacante.private" class="rounded-circle"
                      :src="vacante.company._id_user.photo ? vacante.company._id_user.photo.link : ''"
                      alt="profile image" style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                      <img v-else src="/images/profile.jpg" alt="Logo Company" class="rounded-circle"
                        style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                  </div>
                </div>
              </div>
            </section>
            <hr>
            <section>
              <div>
                <h5 style="font-size: 1.2rem;">Sobre el empleo:</h5>
              </div>
              <div class="vac-description">
                <p style="text-align: justify;">Educación mínima requerida: {{ vacante.educacionMinima }}</p>
              </div>
              <div style="display: flex;">
                <div class="vac-modalidad">
                  <p style="text-align: justify;">Modalidad: {{ vacante.modalidad }}</p>
                </div>
                <div class="vac-contratacion" v-if="vacante.contratacion">
                  <p style="text-align: justify;">Contratación: {{ vacante.contratacion }}</p>
                </div>
              </div>
              <hr>
              <div class="subtitle-vac-a">
                <h5 style="font-size: 1.2rem;">Información de la vacante:</h5>
              </div>
              <div class="vac-description">
                <p style="text-align: justify;" v-html="vacante.descripcion"></p>
              </div>
              <div class="subtitle-vac-a">
                <h5 style="font-size: 1.2rem;">Habilidades requeridas:</h5>
              </div>
              <div class="vac-description">
                <p style="text-align: justify;" v-html="vacante.habilidades"></p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    </main>
  </template>
  <script>
  import moment from 'moment-timezone';
  moment.locale('es')
  import { CompanyService } from "@/services";
  import { UserService } from "@/services";
  
  export default {
    data: () => ({
      moment: moment,
      search: '',
      searchInput: '',
      listVacantes: [],
      vacante: {},
      modalVacante: {},
      questions: [],
      window: window.innerWidth,
      response: {
        loading: false,
      },
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
    mounted() {
      this.getVacantes().then(() => {
        if (this.listVacantes.length > 0) {
          this.viewVacante(this.listVacantes[0]); // Llama a viewVacante con el primer ítem
        }
  
        const anchoVentana = window.innerWidth;
        if (anchoVentana <= 1024) {
          const divParaOcultar = document.getElementById("information");
          divParaOcultar.style.display = "none";
        }
      });
    },
    methods: {
      capitalizarPrimeraLetra(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() + '...';
      },
      contarElementos(array) {
        let contador = 0;
        for (let elemento of array) {
          console.log(elemento);
          contador++;
        }
        return contador;
      },
      async viewVacante(item) {
        try {
          item.loading = true
          const resp = await CompanyService.vacantesView({ 'id_company': item.id_company });
          const { data } = resp.data;
          item.company = data;
          this.vacante = item;
          this.questions = item.questions;
          this.questions.forEach((i) => {
            i.ideal_answer = '';
          });
          item.loading = false;
  
          var anchoVentana = window.innerWidth;
  
          if (anchoVentana <= 1024) {
            const divParaMostrar = document.getElementById("information");
            const divParaOcultar = document.getElementById("vacante");
            divParaOcultar.style.display = "none";
            divParaMostrar.style.display = "block";
          }
  
  
        } catch ($e) {
          item.loading = false;
          console.error($e)
        }
      },
      async getVacantes() {
        const resp = await UserService.getVacantesCompanies({ search: this.search });
        const { data } = resp.data;
        this.listVacantes = data;
      },
    //   filter(vacante) {
    //     if (this.searchInput != "") {
    //       return vacante.titulo.toLowerCase().match(this.searchInput.toLocaleLowerCase())
    //         || vacante.ubicacion.toLowerCase().match(this.searchInput.toLocaleLowerCase())
    //         || vacante.modalidad.toLowerCase().match(this.searchInput.toLocaleLowerCase());
    //     }
    //     else {
    //       return vacante;
    //     }
    //   },
      filter(vacante) {
        if (!vacante.publishUniversities) {
            return false; // Oculta vacantes sin el campo publishUniversities
        }

        if (this.searchInput != "") {
            return vacante.titulo.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                vacante.ubicacion.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                vacante.modalidad.toLowerCase().includes(this.searchInput.toLowerCase());
        }
        return true; // Muestra todas las vacantes que tengan publishUniversities cuando no hay búsqueda
     },

      sortVacantesFecha(vacantes) {
        vacantes.sort((a, b) => {
          if (a.createdAt == b.createdAt) {
            return 0;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 1;
        });
        return vacantes;
      },
      returnView() {
        var anchoVentana = window.innerWidth;
        if (anchoVentana <= 1024) {
          const divParaMostrar = document.getElementById("information");
          const divParaOcultar = document.getElementById("vacante");
          divParaOcultar.style.display = "block";
          divParaMostrar.style.display = "none";
        }
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../styles/vacantesUniversidad.scss"
  </style>